
import { defineComponent, ref, onMounted } from "vue";
import Swal from "sweetalert2/dist/sweetalert2.js";
import { useRouter } from "vue-router";
import { mask } from "vue-the-mask";
import { useStore } from "vuex";
import { setCurrentPageBreadcrumbs } from "@/core/helpers/breadcrumb";
import { Actions } from "@/store/enums/StoreEnums";
import { ActionsFi } from "@/store/enums/StoreFiEnums";
import JwtService from "@/core/services/JwtService";
import { ElTable } from "element-plus";
import moment from "moment";

export default defineComponent({
  name: "add-client-modal",
  directives: { mask },
  components: {
  },

  setup(props, { emit }) {
    const store = useStore();
    const supplier_data = ref<Array<Supplier>>([]);
    interface Supplier {
      company_id: number;
      company_name: string;
      company_name_search: string;
      company_name_alternative: string;
      company_nob_id: number;
      company_nob_name: string;
      company_group_type_id: number;
      company_group_type_name: number;
      company_industry_type_id: number;
      company_industry_type_name: string;
      company_business_type_id: number;
      company_business_type_name: string;
      company_roc_id: number;
      company_roc_name: string;
      company_doi: string;
      company_cin_llpin: string;
      company_registration_number: string;
      company_pan: string;
      company_website: string;
      company_verified_yes_no: number;
      company_trade_type_id: number;
      active: number;
    }

    var tableData = ref<Array<WIJournal>>([]);
    const branch_data = ref([]);
    const multipleTableRef = ref<InstanceType<typeof ElTable>>();
    const customer_branch_data = ref<Array<any>>([]);
    const invoiceChargesList:any = ref([]);
    const type_data = ref([
      {
        id: 1,
        name: "Credit",
      },
      {
        id: 2,
        name: "Debit",
      },
    ]);

    const invoice_no_data = ref([]);
    const invoice_details_data = ref({});
    const invoice_product_list:any = ref([]);
    const reason_data = ref([]);
    const transaction_data = ref([
      {
        id: 1,
        name: "Sale",
      },
      {
        id: 2,
        name: "Purchase",
      },
    ]);

    onMounted(async () => {
      setCurrentPageBreadcrumbs("Create Credit / Debit Note", []);
       await getReasonList();
       await getProductChargeList();
    });

    const formRef = ref<null | HTMLFormElement>(null);
    const loading = ref<boolean>(false);
    const router = useRouter();

    interface WIJournal {

      sales_invoice_details_id: string;
      sales_invoice_no: string;
      sales_invoice_date: string;
      product_name: string;
      brand_name: string;
      product_qunatity: string;
      product_billing_qunatity: string;
      uom_name: string;
      product_rate: string;
      amount: string;
      total_amount: string;
      is_igst_applicable: string;
    }

    const formData = ref({
      type_select: 1,
      client_select: "",
      buyer_branch_select:'',
      date: moment().format("YYYY-MM-DD"),
      reason_select: 2,
      transaction_select: 1,
      invoice_no_select: "",
    });

    const rules = ref({
      type_select:[
        {
          required: true,
          message: "Type is required",
          trigger: "change",
        },
      ],
      client_select: [
        {
          required: true,
          message: "Buyer is required",
          trigger: "change",
        },
      ],
      buyer_branch_select: [
        {
          required: true,
          message: "Buyer company branch is required",
          trigger: "change",
        },
      ],
      reason_select: [
        {
          required: true,
          message: "Reason is required",
          trigger: "change",
        },
      ],
      transaction_select: [
        {
          required: true,
          message: "Transaction is required",
          trigger: "change",
        },
      ],
      invoice_no_select: [
        {
          required: true,
          message: "Invoice No is required",
          trigger: "change",
        },
      ],
    });

    const setCompanyData = async (data) => {
      let loading_amount = '';
      let freight_amount = '';
      let insurance_amount = '';
      let packing_amount = '';
      let unloading_amount = '';
      let tcs_amount = '';
      let weighing_amount = '';
      let total_igst_amount = '';
      let total_cgst_amount = '';
      let total_sgst_amount = '';
      let round_off_total_amount = '';
      let all_grand_total_amount = '';
      invoiceChargesList.value.map((data: any) => {
        if(data.charge_id == 1){
          loading_amount = data.charge_amount;
        }else if(data.charge_id == 2){
          freight_amount = data.charge_amount;
        }else if(data.charge_id == 3){
          insurance_amount = data.charge_amount;
        }else if(data.charge_id == 4){
          packing_amount = data.charge_amount;
        }else if(data.charge_id == 5){
          unloading_amount = data.charge_amount;
        }else if(data.charge_id == 6){
          tcs_amount = data.charge_amount;
        }else if(data.charge_id == 7){
          weighing_amount = data.charge_amount;
        }else if(data.charge_id == 101){
          total_igst_amount = data.igst_amount;
          total_cgst_amount = data.cgst_amount;
          total_sgst_amount = data?.sgst_amount
        }else if(data.charge_id == 102){
          round_off_total_amount = data.charge_amount;
        }else if(data.charge_id == 103){
          all_grand_total_amount = data?.charge_total_amount;
        }
      });
      let productDataList: any = [];
      let grandTotal = invoiceChargesList.value.filter((data)=> {return data.charge_id == 103})
      console.log('grandTotal',grandTotal)
      for(let data of invoice_product_list.value ){
        if(data.isStatic != true){
          productDataList.push({
            sales_invoice_details_id : data?.si_detail_id,
            product_qunatity : data?.product_quantity,
            product_rate : data?.product_rate,
            product_total : data?.amount,
            igst_amount : data?.igst,
            cgst_amount : data?.cgst,
            sgst_amount : data?.sgst,
            product_grand_total_amount :grandTotal[0].charge_total_amount
        })
        }
      }

      if (productDataList.length <= 0) {
        console.log("validation failed");
        Swal.fire({
          text: "Required At Least One Product. Please select Reason",
          icon: "error",
          buttonsStyling: false,
          confirmButtonText: "Ok, got it!",
          customClass: {
            confirmButton: "btn btn-primary",
          },
        });
        loading.value = false;
        return;
      }
      // console.log(JSON.stringify(db_data));

    let requestBody = {
        buyer_company_id: data.client_select,
        transaction_type : data.transaction_select,
        crdr_reason_id : data.reason_select,
        crdr_note_type : data.type_select,
        sales_invoice_id : data.invoice_no_select,
        crdr_node_date : moment(data.date).format("YYYY-MM-DD"),
        all_product_total_amount : "15000.00",
        loading_amount : loading_amount,
        freight_amount : freight_amount,
        insurance_amount : insurance_amount,
        packing_amount : packing_amount,
        unloading_amount : unloading_amount,
        tcs_amount : tcs_amount,
        weighing_amount : weighing_amount,
        total_igst_amount : total_igst_amount,
        total_cgst_amount : total_cgst_amount,
        total_sgst_amount : total_sgst_amount,
        round_off_total_amount : round_off_total_amount,
        all_grand_total_amount : all_grand_total_amount,
        product_data : productDataList
            
    }

      // try {
      //   await store
      //     .dispatch(ActionsFi.CUST_CREATE_SALES_OFFER, requestBody)
      //     .then(({ data, is_error }) => {
      //       loading.value = false;
      //       if (data?.crdr_note_id) {
      //       setTimeout(() => {
      //         loading.value = false;

      //         Swal.fire({
      //           text: "Form has been successfully submitted!",
      //           icon: "success",
      //           buttonsStyling: false,
      //           confirmButtonText: "Ok, got it!",
      //           customClass: {
      //             confirmButton: "btn btn-primary",
      //           },
      //         }).then(() => {
      //           router.push({ name: "apps-sales-credit-debit-listing" });
      //           emit("refreshList");
      //         });
      //       }, 2000);
      //       }else{
      //         loading.value = false;
      //         Swal.fire({
      //           text: "Sorry, looks like there are some errors detected, please try again.",
      //           icon: "error",
      //           buttonsStyling: false,
      //           confirmButtonText: "Ok, got it!",
      //           customClass: {
      //             confirmButton: "btn btn-primary",
      //           },
      //         });
      //       }
      //     })
      //     .catch(({ response }) => {
      //       loading.value = false;
      //       console.log(response);
      //       Swal.fire({
      //         text: "Sorry, looks like there are some errors detected, please try again.",
      //         icon: "error",
      //         buttonsStyling: false,
      //         confirmButtonText: "Ok, got it!",
      //         customClass: {
      //           confirmButton: "btn btn-primary",
      //         },
      //       });
      //     });
      
      // } catch (e) {
      //   loading.value = false;
      //   console.log(e);
      // }
    };

    const submit = async () => {
      loading.value = true;

      if (!formRef.value) {
        loading.value = false;
        return;
      }

      formRef.value.validate(async (valid) => {
        if (valid) {
          await setCompanyData(formData.value);
        } else {
          loading.value = false;
          return false;
        }
      });
    };




    async function getCompanyList() {
      let values = { trade_type: 1 };
      await store
        .dispatch(Actions.CUST_GET_SUPPLIER_LIST, values)
        .then(({ data }) => {
          debugger;
          supplier_data.value = data;
        })
        .catch(({ response }) => {
          console.log(response);
        });
    }


    const getTableData = async (data) => {
      await getProductChargeList();
      try {
        var values = {
          sales_invoice_id: formData.value["invoice_no_select"],
        };
        console.log(values);
        await store
          .dispatch(ActionsFi.CUST_GET_CR_DR_INVOICE_DETAILS, values)
          .then(({data}) => {
            invoice_details_data.value = data 
            let productDetailsData = data?.product_details?.map((item) =>{
              return {...item,
                    si_no: data.si_no,
                    si_date: data.si_date,
                    igst_yn: data.igst_yn,           
                }
            })
            invoice_product_list.value  = productDetailsData;
            console.log('invoice_details_data', invoice_product_list.value);
            if([1,2,3,4].includes(formData.value.reason_select)){
              invoice_product_list.value = invoice_product_list.value.map((item)=>{
                return {...item,
                      charge_amount:'0.00',
                      amount:'0.00',
                      taxableAmount:'0.00',
                      igst:'0.00',
                      cgst:'0.00',
                      sgst:'0.00',
                      total_amount:'0.00'
                  }
              })
              invoice_product_list.value = invoice_product_list.value.concat(invoiceChargesList.value)
              console.log('invoice_product_list',invoice_product_list.value)
            }
          })
          .catch(({ response }) => {
            tableData.value = [];
          });
      } catch (e) {
        console.log(e);
      }
    };

    async function getSupplierList(query: string) {
      debugger;
      if (query.length < 3) {
        return;
      }
      let values = { search_term: query };
      await store
        .dispatch(Actions.CUST_GET_SUPPLIER_DROPDOWN_LIST, values)
        .then(({ data }) => {
          debugger;
          supplier_data.value = data;
        })
        .catch(({ response }) => {
          console.log(response);
        });
    }

    async function getCustomerBranchList(branchFor = 'buyer') {
      let values = {
        company_id: branchFor == 'buyer' ? formData.value["client_select"] :
                                formData.value["consignee_select"],
        company_branch_id: 0,
        page: 1,
        records_per_page: 1000,
      };
      await store
        .dispatch(Actions.CUST_GET_BRANCH_LIST, values)
        .then(({ data }) => {
          debugger;
          customer_branch_data.value = data?.result_list;
        })
        .catch(({ response }) => {
          console.log(response);
        });
    }

    async function getReasonList() {
      let user = JSON.parse(JwtService.getToken());
      let params = {user_id: user.user_id};
      await store
        .dispatch(ActionsFi.CUST_GET_CREDIT_DEBIT_REASON_LISTING_DATA, params)
        .then(({ data }) => {
          debugger;
          reason_data.value = data;
        })
        .catch(({ response }) => {
          console.log(response);
        });
    }

    async function getInvoiceData() {
      let params = {
        customer_company_id: formData.value["client_select"],
        customer_company_branch_id: formData.value['buyer_branch_select']
      };
      await store
        .dispatch(ActionsFi.CUST_GET_SALES_INVOICE_BY_ID_LIST, params)
        .then(({ data }) => {
          if (!data || data?.length <= 0) {
            invoice_no_data.value = [];
          } else {
            invoice_no_data.value = data;
          }
        })
        .catch(({ response }) => {
          console.log(response);
        });
    }

    const getProductChargeList = async() => {
      var user = JSON.parse(JwtService.getToken());
      let param = {user_id: user.user_id}
      try {
        await store
          .dispatch(ActionsFi.CUST_SALES_INVOICE_CHARGE_LIST,param)
          .then(({ data }) => {
            debugger;
            invoiceChargesList.value = data;
            console.log('invoice charges list', invoiceChargesList.value)
          })
          .catch(({ response }) => {
            console.log(response);
          });
      } catch (e) {
        console.log(e);
      }
    }

    const calculateQtyDiffTableFields = (editedRow, fieldName) => {
      if(!(/^\d*\.?\d*$/.test(editedRow['qtyDiff']))){
         editedRow['qtyDiff'] = editedRow['qtyDiff'].slice(0, -1);;
         return;
      }
        let qtyDiff = parseFloat(editedRow.qtyDiff) ? parseFloat(editedRow.qtyDiff) : 0;
        let rate = parseFloat(editedRow.product_rate) ? parseFloat(editedRow.product_rate) : 0;
        let amount = qtyDiff * rate;
        let appliedTax = 0;
        editedRow.amount = (amount).toFixed(2);
        editedRow.taxableAmount = (amount).toFixed(2);
        if(invoice_details_data.value['igst_yn'] === 1){
          appliedTax = amount * 18 / 100;
          editedRow.igst = appliedTax.toFixed(2);
        }else{
          appliedTax = amount * 9 / 100;
          editedRow.cgst = appliedTax.toFixed(2);
          editedRow.sgst = appliedTax.toFixed(2);
          appliedTax = appliedTax * 2;
        }
        editedRow.total_amount = (amount + appliedTax).toFixed(2);
        setStaticFiledData();
    }


    const setStaticFiledData = () => {
        let productAmountSubTotal = 0;
        let igstSubTotal = 0;
        let cgstSubTotal = 0;
        let sgstSubTotal = 0;
        let grandSubTotal = 0;
        let productAmountTotal = 0;
        let igstTotal = 0;
        let cgstTotal = 0;
        let sgstTotal = 0;
        let grandTotal = 0;
        let round = 0;
        let roundUp = 0;
        // let qtyTotal = 0;

      // invoiceChargesList.value.map((row)=> {
      //   row.charge_amount= '',
      //   row.igst_amount= '',
      //   row.cgst_amount='',
      //   row.sgst_amount='',
      //   row.charge_total_amount= ''
      // });

      invoice_product_list.value.map((data: any) => {
        if(!data.isStatic){
              productAmountSubTotal = parseFloat(data?.amount) + productAmountSubTotal;
              grandSubTotal = parseFloat(data?.total_amount) + grandSubTotal;
              // qtyTotal = parseFloat(data?.dispatch_quantity) + qtyTotal;
              if(invoice_details_data.value['igst_yn'] === 1){
                igstSubTotal = parseFloat(data?.igst) + igstSubTotal;
              }else{
                cgstSubTotal = parseFloat(data?.cgst) + cgstSubTotal;
                sgstSubTotal = parseFloat(data?.sgst) + sgstSubTotal;
              }
        }
       });

      invoiceChargesList.value.map((row) => {
        if(![6,100, 101, 102, 103].includes(row.charge_id)){
           let productAmount = row?.charge_amount ? row.charge_amount : '0'
           let grandAount =  row?.charge_total_amount ? row.charge_total_amount : '0'
           
           productAmountTotal = parseFloat(productAmount) + productAmountTotal;
           grandTotal = parseFloat(grandAount) + grandTotal;
           if(invoice_details_data.value['igst_yn'] === 1){
            let igstAmount =  row?.igst_amount ? row.igst_amount : '0'
            igstTotal = parseFloat(igstAmount) + igstTotal;
           }else{
            let cgstAmount =  row?.cgst_amount ? row.cgst_amount : '0';
            let sgstAmount = row?.sgst_amount ? row.sgst_amount : '0';
            cgstTotal = parseFloat(cgstAmount) + cgstTotal;
            sgstTotal = parseFloat(sgstAmount) + sgstTotal;
           }
        }
      });
       
      round = Math.round(grandTotal + grandSubTotal);
      invoiceChargesList.value.map((row)=> {
          if(row?.charge_id === 100){
           row.charge_amount = parseFloat(productAmountSubTotal.toString()).toFixed(2) ;
           row.charge_total_amount = parseFloat(grandSubTotal.toString()).toFixed(2);
          //  row.rate = qtyTotal.toFixed(2);
           if(invoice_details_data.value['igst_yn'] === 1){
            row.igst_amount  = parseFloat(igstSubTotal.toString()).toFixed(2) ;
           }else{
            row.cgst_amount = parseFloat(cgstSubTotal.toString()).toFixed(2);
            row.sgst_amount = parseFloat(sgstSubTotal.toString()).toFixed(2);
           }
          }
          else if(row.charge_id === 6){
            row.charge_amount = parseFloat(grandSubTotal.toString()).toFixed(2)
          }
          else if(row.charge_id === 101){
            row.charge_amount = parseFloat((productAmountTotal+ productAmountSubTotal).toString()).toFixed(2);
            row.charge_total_amount = parseFloat((grandTotal + grandSubTotal).toString()).toFixed(2);
            row.rate = parseFloat(productAmountSubTotal.toString()).toFixed(2)
            if(invoice_details_data.value['igst_yn'] === 1){
              row.igst_amount = parseFloat((igstTotal + igstSubTotal).toString()).toFixed(2);
            }else{
              row.cgst_amount = parseFloat((cgstTotal + cgstSubTotal).toString()).toFixed(2);
              row.sgst_amount = parseFloat((sgstTotal + sgstSubTotal).toString()).toFixed(2);
            }
          }
          else if(row.charge_id === 102){
            roundUp = grandTotal + grandSubTotal - round
            row.charge_total_amount = parseFloat(roundUp.toString()).toFixed(2)
         }

          else if(row.charge_id === 103){
            row.charge_total_amount = parseFloat((round).toString()).toFixed(2);
          }
      })
    }


  const calculateStaticTableFields = (editedRow, column_name) => {
      if(editedRow[column_name] === ''){
          editedRow.charge_amount = '';
          editedRow.igst_amount = '';
          editedRow.cgst_amount = '';
          editedRow.sgst_amount = '';
          editedRow.charge_total_amount = '';
      }
        else if(!(/^\d*\.?\d*$/.test(editedRow[column_name]))){
         editedRow[column_name] = editedRow[column_name].slice(0, -1);;
         return;
      }
            
      console.log('static selected row',editedRow)
      let calculatedValue = 0;
      let productAmountColumn = parseFloat(editedRow.charge_amount ? editedRow.charge_amount : '0');
      let igstAmountColumn = parseFloat(editedRow.igst_amount ? editedRow.igst_amount : '0');
      let productGrandTotalColumn =  parseFloat(editedRow.charge_total_amount ? editedRow.charge_total_amount : '0');

      if(invoice_details_data.value['igst_yn'] === 1){
        let igst  = (productAmountColumn * 18)/100;
        calculatedValue =  productAmountColumn + igst;
        editedRow.igst_amount = parseFloat(igst.toString()).toFixed(2);
      }else{
        let cgst = (productAmountColumn * 9)/100;
        let sgst = (productAmountColumn * 9)/100;
        calculatedValue =  productAmountColumn + cgst + sgst;
        editedRow.cgst_amount = parseFloat(cgst.toString()).toFixed(2);
        editedRow.sgst_amount = parseFloat(sgst.toString()).toFixed(2);
      }

      editedRow.charge_total_amount = parseFloat(calculatedValue.toString()).toFixed(2);

      let chargeAmount = 0;
      let igstChargeAmount = 0;
      let cgstChargeAmount = 0;
      let sgstChargeAmount  = 0;
      let chargeTotalAmount = 0;
      let roundUp = 0;
      let round = 0;
        for(let row of invoiceChargesList.value){
          if([6, 101, 102, 103].includes(row.charge_id)){
            continue;
          }
            let chargeAmountVal = row.charge_amount ? row.charge_amount : '0';
            let chargeTotalAmountVal = row.charge_total_amount ? row.charge_total_amount : '0';
            chargeAmount = parseFloat(chargeAmountVal) + chargeAmount;
            chargeTotalAmount = parseFloat(chargeTotalAmountVal) + chargeTotalAmount;
            if(invoice_details_data.value['igst_yn'] === 1){
              let igstAmountVal = row.igst_amount ? row.igst_amount : '0';
              igstChargeAmount = parseFloat(igstAmountVal) + igstChargeAmount;
            }else{
              let cgstAmount = row.cgst_amount ? row.cgst_amount : '0';
              let sgstAmount = row.sgst_amount ? row.sgst_amount : '0';
              cgstChargeAmount = parseFloat(cgstAmount) + cgstChargeAmount;
              sgstChargeAmount = parseFloat(sgstAmount) + sgstChargeAmount;
            }
        }


        invoiceChargesList.value.map((row) => {
         if(row.charge_id === 101){
           row.charge_amount = parseFloat(chargeAmount.toString()).toFixed(2);
           row.charge_total_amount = parseFloat(chargeTotalAmount.toString()).toFixed(2);
           if(invoice_details_data.value['igst_yn'] === 1){
            row.igst_amount = parseFloat(igstChargeAmount.toString()).toFixed(2);
           }else{
            row.cgst_amount = parseFloat(cgstChargeAmount.toString()).toFixed(2);
            row.sgst_amount = parseFloat(sgstChargeAmount.toString()).toFixed(2);
           }
           let toatlCharge  = chargeTotalAmount;
          round = Math.round(toatlCharge);
         }
          else if(row.charge_id === 102){
            roundUp = chargeTotalAmount-round
            row.charge_total_amount = parseFloat(roundUp.toString()).toFixed(2)
         }
         else if(row.charge_id === 103){
           row.charge_total_amount = parseFloat(round.toString()).toFixed(2)
         }
     })

    }

    const calculateRateTableFields = (editedRow, fieldName) => {
      if(!(/^\d*\.?\d*$/.test(editedRow['rateDiff']))){
         editedRow['rateDiff'] = editedRow['rateDiff'].slice(0, -1);;
         return;
      }
        let rateDiff = parseFloat(editedRow.rateDiff) ? parseFloat(editedRow.rateDiff) : 0;
        let qty = parseFloat(editedRow.product_quantity) ? parseFloat(editedRow.product_quantity) : 0;
        let amount = rateDiff * qty;
        let appliedTax = 0;
        editedRow.amount = (amount).toFixed(2);
        editedRow.taxableAmount = (amount).toFixed(2);
        if(invoice_details_data.value['igst_yn'] === 1){
          appliedTax = amount * 18 / 100;
          editedRow.igst = appliedTax.toFixed(2);
        }else{
          appliedTax = amount * 9 / 100;
          editedRow.cgst = appliedTax.toFixed(2);
          editedRow.sgst = appliedTax.toFixed(2);
          appliedTax = appliedTax * 2;
        }
        editedRow.total_amount = (amount + appliedTax).toFixed(2);
        setStaticFiledData();
    }

    const calculateRetrunMaterialTableFields = (editedRow, fieldName) => {
      if(!(/^\d*\.?\d*$/.test(editedRow[fieldName]))){
         editedRow[fieldName] = editedRow[fieldName].slice(0, -1);;
         return;
      }else if(fieldName == 'returnPCS'){
         return;
      }
        let qtyDiff = parseFloat(editedRow.returnQty) ? parseFloat(editedRow.returnQty) : 0;
        let rate = parseFloat(editedRow.product_rate) ? parseFloat(editedRow.product_rate) : 0;
        let amount = qtyDiff * rate;
        let appliedTax = 0;
        editedRow.amount = (amount).toFixed(2);
        editedRow.taxableAmount = (amount).toFixed(2);
        if(invoice_details_data.value['igst_yn'] === 1){
          appliedTax = amount * 18 / 100;
          editedRow.igst = appliedTax.toFixed(2);
        }else{
          appliedTax = amount * 9 / 100;
          editedRow.cgst = appliedTax.toFixed(2);
          editedRow.sgst = appliedTax.toFixed(2);
          appliedTax = appliedTax * 2;
        }
        editedRow.total_amount = (amount + appliedTax).toFixed(2);
        setStaticFiledData();
        
    }

    return {
      formData,
      rules,
      submit,
      formRef,
      loading,
      tableData,
      branch_data,

      getCompanyList,
      getTableData,
      supplier_data,
      multipleTableRef,
      type_data,
      reason_data,
      invoice_no_data,
      getInvoiceData,
      getSupplierList,
      transaction_data,

      getCustomerBranchList,
      customer_branch_data,
      invoice_details_data,
      invoice_product_list,
      getProductChargeList,
      calculateStaticTableFields,
      calculateQtyDiffTableFields,
      calculateRateTableFields,
      calculateRetrunMaterialTableFields
    };
  },
});
